import React from 'react'
import { Link, graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import MarketBadge from '../components/MarketBadge'
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const BusinessTemplate = (props) => {

  const isFounder = () => {
    if (props.pageContext.businessRef.founders[1]) {
      return (
        <BS.Card style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
          <div>
            <b>Founder</b>  {props.pageContext.businessRef.founders[1].name}
          </div>
        </BS.Card.Body>
        </BS.Card>
      )
    }
  }

  // const isLogo = () => {
  //   if (props.pageContext.businessRef.localImage.publicURL !== '/static/ba1252a8ae734065b3e3ef7ed2320180/1.png' && props.pageContext.businessRef.imgTWhite === false) {
  //       return (
  //         <BS.ListGroupItem><BS.Card.Img style={{ marginTop: 30, maxWidth: 200}} variant="top" src={props.pageContext.businessRef.localImage.publicURL} alt={`${props.pageContext.businessRef.name} logo`} /></BS.ListGroupItem>
  //       )
  //   }
  //   if (props.pageContext.businessRef.localImage.publicURL !== '/static/ba1252a8ae734065b3e3ef7ed2320180/1.png' && props.pageContext.businessRef.imgTWhite === true) {
  //     return (
  //       <BS.ListGroupItem style={{background: '#663499'}}><BS.Card.Img style={{ marginTop: 30, maxWidth: 200}} variant="top" src={props.pageContext.businessRef.localImage.publicURL} alt={`${props.pageContext.businessRef.name} logo`} /></BS.ListGroupItem>
  //     )
  // }

  // }


  const isLogo2 = () => {
    if (props.pageContext.imageFile !== 'none' && props.pageContext.imgTWhite === false) {
      return (
        <section>

         <img  style={{ margin: '30px 25px', maxWidth: 200}} variant="top" src={`/images/business/${props.pageContext.imageFile}`} alt={`${props.pageContext.name} logo`}></img>
        </section>
      )
  } 
  
  else if (props.pageContext.imageFile !== 'none' && props.pageContext.imgTWhite === true){
    return (
      <div style={{background: '#663499'}}>
        <img style={{ margin: '30px 25px', maxWidth: 200}} variant="top" src={`/images/business/${props.pageContext.imageFile}`} alt={`${props.pageContext.name} logo`}></img>
      </div>
    )
  }
}


  const isLocation = () => {
    if(props.pageContext.businessRef.locations[0].city !== 'none' && props.pageContext.businessRef.locations.length === 1){
      return (
        <BS.ListGroupItem><b>Location</b> - <Link to={`/${props.pageContext.businessRef.locations[0].country.toLowerCase().replace(/ /g, "-")}/${props.pageContext.businessRef.locations[0].city.toLowerCase().replace(/ /g, "-")}/`}>{props.pageContext.businessRef.locations[0].city}, {props.pageContext.businessRef.locations[0].country}</Link></BS.ListGroupItem>
      )
    } else if (props.pageContext.businessRef.locations[0].city !== 'none' && props.pageContext.businessRef.locations.length === 2){
      return (
        <section>
        <BS.ListGroupItem><b>Location</b> - <Link to={`/${props.pageContext.businessRef.locations[0].country.toLowerCase().replace(/ /g, "-")}/${props.pageContext.businessRef.locations[0].city.toLowerCase().replace(/ /g, "-")}/`}>{props.pageContext.businessRef.locations[0].city}, {props.pageContext.businessRef.locations[0].country}</Link></BS.ListGroupItem>
        <BS.ListGroupItem><b>Location</b> - {props.pageContext.businessRef.locations[1].city}, {props.pageContext.businessRef.locations[1].country}</BS.ListGroupItem>
        </section>
      )
    }
    

    else {

      if (props.pageContext.businessRef.city) {
        if (props.pageContext.businessRef.city === 'London') {
          return (
            <BS.ListGroupItem><b>Location</b> - {props.pageContext.businessRef.city}, United Kingdom</BS.ListGroupItem>
          )
        } else {
          return (
            <BS.ListGroupItem><b>Location</b> - {props.pageContext.businessRef.city}</BS.ListGroupItem>
          )
        }
      }
    }

  }

  const isBrand = () => {
   if(props.pageContext.businessRef.brands[0] !== 'none'){    
    return (
      <BS.ListGroupItem><b>Brands</b> - {props.pageContext.businessRef.brands.map(brand => `${brand} `)}</BS.ListGroupItem>
    )}
}

const isDescription = () => {
  if(props.pageContext.businessRef.description !== 'none'){    
   return (
     <BS.ListGroupItem><b>Description</b> - {props.pageContext.businessRef.description}</BS.ListGroupItem>
   )}
}

const isClient = () => {
  if(props.pageContext.businessRef.clients[0] !== 'none'){    
   return (
     <BS.ListGroupItem><b>Clients</b> - {props.pageContext.businessRef.clients.map(client => `${client} `)}</BS.ListGroupItem>
   )}
}

const isPartner = () => {
  if(props.pageContext.businessRef.partners[0] !== 'none'){    
   return (
     <BS.ListGroupItem><b>Partners</b> - {props.pageContext.businessRef.partners.map(partner => `${partner} `)}</BS.ListGroupItem>
   )}
}

const isTags = () => {
  if(props.pageContext.businessRef.tags[0] !== 'none'){    
   return (
     <BS.ListGroupItem><b>Tags</b> - {props.pageContext.businessRef.tags.map(tag => `${tag} `)}</BS.ListGroupItem>
   )}
}

  const renderInvestment = (edge, index) => {

    const isInvestor_1 = () => {
      if (edge.node.investors[0]) {
        return (
          <span> with < Link to={`/investor/${edge.node.investors[0].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[0]}</Link></span>
        )
      }
    }
    const isInvestor_2 = () => {
      if (edge.node.investors[1]) {
        return (
          <span> and < Link to={`/investor/${edge.node.investors[1].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[1]}</Link></span>
        )
      }
    }

    const isInvestor_3 = () => {

      if (edge.node.investors[2]) {
        return (
          <span> and < Link to={`/investor/${edge.node.investors[2].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[2]}</Link></span>
        )
      }
    }

    const isInvestor_4 = () => {
      if (edge.node.investors[3]) {
        return (
          <span>, < Link to={`/investor/${edge.node.investors[3].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[3]}</Link></span>
        )
      }
    }

    const isInvestor_5 = () => {

      if (edge.node.investors[4]) {
        return (
          <span>, < Link to={`/investor/${edge.node.investors[4].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[4]}</Link></span>
        )
      }
    }

    const isInvestor_6 = () => {

      if (edge.node.investors[5]) {
        return (
          <span>, < Link to={`/investor/${edge.node.investors[5].toLowerCase().replace(/ /g, "-")}/`}>{edge.node.investors[5]}</Link></span>
        )
      }
    }

    const date = new Date(edge.node.date)

    return (
      <BS.Card style={{ marginTop: 5, marginBottom: 5 }} key={index}>
        <BS.Card.Body>
          <b>{edge.node.businessName}</b> raised <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.amount)}</b>{isInvestor_1()}{isInvestor_2()}{isInvestor_3()}{isInvestor_4()}{isInvestor_5()}{isInvestor_6()}<span style={{ fontSize: 12 }}> - {date.toLocaleDateString()}</span>

        </BS.Card.Body>
      </BS.Card>
    )
  }

  
  const metaDescription =`Get to know ${props.pageContext.businessRef.name}! We have mapped out the business with its team structure and activity. This is a also a deep dive into its clients, partners, and private equity investments`

  return (
    <section>
      <Helmet>
        <html lang="en" />
        <title>{props.pageContext.businessRef.name} - Activity, founders and funding with UnicornAlert</title>
        <meta name="description" content={metaDescription} />

      </Helmet>
      
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400 }}>
      <BreadcrumbLite>
        <BS.Breadcrumb.Item> <Link to={`/`}>Home</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item> <Link to={`/business/`}>Business List</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>{props.pageContext.businessRef.name}</BS.Breadcrumb.Item>
      </BreadcrumbLite>

        <BS.Row style={{display: 'block'}}>
              <BS.Col sm={12}>
              <h1>{props.pageContext.businessRef.name} <MarketBadge market={props.pageContext.businessRef.what} /></h1>
                <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Funding, team structure (meet the founders), partners and clients</h2>
                </BS.Col>
        </BS.Row>


          <BS.Row>
            <BS.Col sm={3}>

              <BS.Card >
                
                {isLogo2()}


                <BS.ListGroup className="list-group-flush">
                  <BS.ListGroupItem><a href={props.pageContext.businessRef.website} target="_blank" rel="noreferrer">{props.pageContext.businessRef.website}</a></BS.ListGroupItem>
                  <BS.ListGroupItem><b>Market</b> - <MarketBadge market={props.pageContext.businessRef.what} /></BS.ListGroupItem>
                 {isLocation()}
                 {isBrand()}
                 {isDescription()}
                 {isClient()}
                 {isPartner()}
                 {isTags()}

                </BS.ListGroup>

              </BS.Card>
            </BS.Col>

            <BS.Col sm={9}>

              <BS.Card>
                <BS.Card.Header><h3>Team</h3></BS.Card.Header>
              </BS.Card>

              <BS.Card style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
                <b>Founder</b>  {props.pageContext.businessRef.founders[0]}
              </BS.Card.Body>
              </BS.Card>

              {isFounder()}

              <BS.Card style={{ marginTop: 30 }}>
                <BS.Card.Header><h3>Funding</h3></BS.Card.Header>
              </BS.Card>
              {props.data.allFunding.edges.map(renderInvestment)}


              
            </BS.Col>
          </BS.Row>
        </BS.Container>


    </section >
  )
}

export const query = graphql`
query IndexQuery3 ($businessName: String!, $imageFile: String)
{
  allFunding(filter: {businessName: {eq: $businessName}}, sort: {fields: date, order: DESC}) {
    edges {
      node {
        amount
        businessName
        currency
        date
        investors
      }
    }
    totalCount
  }

  businessLogo: file(relativePath: {eq: $imageFile}) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
}


`


export default BusinessTemplate